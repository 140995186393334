import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "what-you-need-to-know"
    }}>{`What you need to know`}</h1>
    <Vimeo id={424598803} mdxType="Vimeo" />
    <p>{`I’m going to assume you already know how to code and that you're great with
JavaScript. Many books have been written to teach the basics of JavaScript;
this is not one of them.`}</p>
    <p>{`If you're struggling with modern JavaScript syntax, I suggest visiting my
`}<a parentName="p" {...{
        "href": "https://es6cheatsheet.com"
      }}>{`Interactive ES6 Cheatsheet`}</a></p>
    <p>{`I'm assuming you've tried D3 before, or at least heard of it. You don't have to
know how D3 works, or understand what the code you copy pasted to meet a
deadline does. I'll show you all you need to know.`}</p>
    <p>{`If you want to learn D3 in depth, there's plenty of great resources out there.
For example, the third edition of my first ever book,
`}<a parentName="p" {...{
        "href": "https://www.packtpub.com/web-development/d3js-4x-data-visualization-third-edition"
      }}>{`D3.js 4.x Data Visualization - Third Edition`}</a>{`.
Written by Ændrew Rininsland with some chapters left from yours truly.`}</p>
    <p>{`As for React, same deal: I expect you have some idea how to use it, but you'll
be fine even if this is your first time looking at React. We'll go through
everything you need to know as it's happening.`}</p>
    <p>{`All examples in React for DataViz use modern JavaScript. That is JavaScript from ES6
– ES2015 – and beyond. No special mods, just stuff you get by default in all
React projects in recent years. Even if some syntax isn't in the standard yet.`}</p>
    <p>{`I'm going to explain any new syntax that we use.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      